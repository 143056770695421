$(document).ready(function(e) {
  var price_txt_br   = (typeof(rb.language) != 'undefined' && typeof(rb.language.price) != 'undefined') ? rb.language.price : 'Preço',
      product_txt_br = (typeof(rb.language) != 'undefined' && typeof(rb.language.product) != 'undefined') ? rb.language.product : 'Produto',
      qty_txt_br     = (typeof(rb.language) != 'undefined' && typeof(rb.language.qty) != 'undefined') ? rb.language.qty : 'qtd';
  var $cartConfirmCont   = $('.device-pc .block.gnav-cart');

  // updated based on Bugzilla bugIDs:245722, 270222
  if (site.client.isMobile) {
    var $mobileMppElem = $('.mobile_mpp'),
        $elem = null,
        prodClass = '';
    $elem = $mobileMppElem.find('.button.select_shade:visible').closest('li.product.mpp-product');
    $elem.each(function() {
      var $prodElem = $(this).find('> div.shaded .sku_select');
      if($prodElem.hasClass('swatch')) {
        // Shaded products
        $(this).find('.button.select_shade').text('Selecionar Cor');
      } else if($prodElem.hasClass('sized')) {
        // Sized products
        $(this).find('.button.select_shade').text('Selecionar Tamanho');
      }
    });
    $mobileMppElem.find('.button.select_formula').text('Selecionar Fórmula');
    $mobileMppElem.find('.button.add-to-cart').text('Compre Agora');
    var $popupElement = $('#colorbox');
    var $popupOverlay = $('#cboxOverlay');
    if (document.referrer && $popupElement.length && $popupOverlay.length) {
      var referrerUrl = document.referrer.toLowerCase();
      if (referrerUrl.includes('instagram') || referrerUrl.includes('facebook')) {
        $popupElement.add($popupOverlay).addClass('hidden');
      }
    }
  }

  /**
  * Format the price and installments in products display
  */
  var filterPriceNodes = [];
  filterPriceNodes.push('.formatted_price');
  filterPriceNodes.push('.formattedInstallmentPrice');
  filterPriceNodes.push('#formattedInstallmentPrice');
  filterPriceNodes.push('.product-info .product-price');
  filterPriceNodes.push('.order-details ul.price-details li');
  filterPriceNodes.push('.info li');
  filterPriceNodes.push('.cart-item__total');

  function format_br_price() {
    $(filterPriceNodes.join(',')).each(function(){
      var price_string = null;
      price_string = ($(this).html()).replace(/BR\s?/, '');
      price_string = (price_string).replace(/\./gm, ',');
      $(this).html(price_string);
    });
  }

  format_br_price();

  // In SPP pages format prices after RPC response 
  $(document).on('prodcat.status', function() {
    format_br_price();
  });

  // Highlight wishlist icons
  $accFavoritesMyList = $('.account-favorites-mylists');
  $accFavoritesWishList = $('.account-favorites-wishlist');
  $list_icon = $accFavoritesMyList.find('.list.create-list .list-icon');
  $list_icon_overlay = $accFavoritesMyList.find('.add-to-list-overlay .list-icon');
  $list_icon_wList_overlay = $accFavoritesWishList.find('.create-wishlist-form .list-icon');
  if(!$list_icon.find('> span').length) {
    $list_icon.children(':not(.list-icon-msg-label)').wrapAll('<span/>');
  }
  if(!$list_icon_overlay.find('> span').length) {
    $list_icon_overlay.children(':not(.list-icon-msg-label)').wrapAll('<span/>');
  }
  if(!$list_icon_wList_overlay.find('> span').length) {
    $list_icon_wList_overlay.children(':not(.list-icon-msg-label)').wrapAll('<span/>');
  }
  $list_icon_parent = $('body#favorites');
  $list_icon_parent.on('click', '.create-wishlist-form .list-icon span label', function() {
    $list_icon_label = $('.create-wishlist-form .list-icon span label');
    $list_icon_label.removeClass('active');
    $(this).addClass('active');
  });

  /**
   * cartConfirmOverlayText function adds locale specific translation in the popup
   * By default, English language was hard coded in the inline template in CL base
   */
  function cartConfirmOverlayText() {
    $cartHeading = $cartConfirmCont.find('.cart-confirm-wrapper .cart-overlay-products-heading');
    if($cartHeading .length) {
      $cartHeading.find('.hleft').text(product_txt_br);
      $cartHeading.find('.right-copy .hcenter').text(qty_txt_br);
      $cartHeading.find('.right-copy .hright').text(price_txt_br);
    }
  }

  $(document).on('cart.loaded addToCart.success', function() {
    cartConfirmOverlayText();
  })
  $cartConfirmCont.on('hover', function(e) {
    cartConfirmOverlayText();
  });

  // Align's the buynow button to same height
  $('.best-sellers').addClass('normalizeHeight');

  // Add to bag in past purchases didn't attach events properly, so re-bind events
  $('a.view-details-button').on( "click", function(e) {
    $addLinks = $('.my-account-sku .add-to-bag').not('.device-mobile .my-account-sku .add-to-bag') ;
    $addLinks.off('click');
    $addLinks.on( "click", function(e) {
      e.preventDefault();
      var $addLink = $(this);
      var progressNode = $(this).siblings('.add-progress');

      if ( progressNode.length ) {
        $addLink.hide();
        progressNode.show();
        $(document).one("addToCart.success addToCart.failure", function () {
          progressNode.hide();
          $addLink.show();
        });
      }
      var freq = $addLink.attr("data-freq");
      var args = {
        skuBaseId: $addLink.attr("data-skubaseid")
      };
      if (typeof freq !== 'undefined' && freq !== false) {
        args.REPLENISHMENT_FREQ = freq;
      }
      site.addToCart(args);
    });
  });

  // Display order details for the transaction requested from email link
  function showBoletoItem() {
    var $boletoFormCont = $('.payment_boleto_print');
    if($boletoFormCont.length) {
      var orderId = ($('.payment_boleto_print form input[name="numdoc"]').val());
      var str = 'order-'+orderId;
      var $thisItemObj = $('#'+str+'-details');
      $thisItemObj.siblings('.order-info').remove();
      if($('body').hasClass('device-pc')) {
        $('ul#'+str).parent().siblings('.outer-wrap').remove();
        $('#order-' + orderId + '-details').toggleClass('hide');
      } else if($('body').hasClass('device-mobile')) {
        $('#'+str).siblings('.order-header-wrapper').remove();
        $('#'+str).closest('.past-purchases').addClass('order-print-boleto');
      }
      $thisItemObj.find('.button.add-all-to-bag').before($boletoFormCont);
      $boletoFormCont.find('form input.bto_gerar_boleto').addClass('button');

      $('.order .view-details-button').off('click').on('click', function( event ) {
        event.preventDefault();
        var orderId = $(this).attr("data-orderid");
        $('#order-' + orderId + '-details').toggleClass('hide');

      // Check if mobile device and toggle active and caret classes
        if ( $('body').hasClass('device-mobile') ) {
          $('.order-print-boleto .button.view-details-button, .order-print-boleto .item.first').hide();
          $('.order-print-boleto.past-purchases .order-info.accordion-content').show();
        }
      }).trigger('click');
    }
  }
  showBoletoItem();

});
